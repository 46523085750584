// 四有档案
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'WDMC',
                label: '遗产要素名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LB',
                label: '遗产要素类型',
            }, {
                prop: 'LB',
                label: '遗产要素描述',
            }, {
                prop: 'LB',
                label: '对应的文保单位',
            }, {
                prop: 'LJ',
                label: '照片',
                showFileBtn: true,
            }, {
                prop: 'LB',
                label: '历史版本',
            }],
        },
    },
    snSearch: {
        // selectData: [{
        //     label: '类型',
        //     clearable: true,
        //     placeholder: "请选择大会决议类型",
        //     list: [{
        //         ID: '1',
        //         NAME: '列入时决议'
        //     }, {
        //         ID: '2',
        //         NAME: '补充决议'
        //     }],
        //     optionLabel: 'NAME',
        //     optionValue: 'ID',
        //     value: 'LX',
        //     operateType: 'search',
        //     isShow: true
        // }],
    },
};
export default selfData;